.movie{

}

.movie .banner{
    height: 400px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
}

.movie .banner .overlay{
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    width: 100%;
    height: 100%;
}

.movie .banner .content{
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 36px 0;
}

.movie .banner .content .poster{
    height: 100%;
    display: flex;
    width: 250px;
    justify-content: flex-end;
    padding-left: 36px;
    position: relative;
}

.movie .banner .content .poster img{
    height: 100%;
    border-radius: 12px;
}

.movie .banner .content .details{
    padding: 0 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.movie .banner .content .details h1{
    font-size: 26px;
    margin: 0;
}

.horizontal{
    display: flex;
    gap: 12px;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 8px;
}

.movie .similar{
    width: 100%;
}

.movie .similar .movie{
    width: 200px;
    flex: 0 0 160px;
}

.movie .cast{
    width: 100%;
}

.movie .cast .actor{
    width: 200px;
    flex: 0 0 160px;
}

.movie .crew{
    width: 100%;
}

.movie .crew .person{
    width: 200px;
    flex: 0 0 160px;
}